export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const STUDENT_AUTH_SUCCESS = "STUDENT_AUTH_SUCCESS";
export const STUDENT_AUTH_FAIL = "STUDENT_AUTH_FAIL";
export const EMPLOYEE_AUTH_SUCCESS = "EMPLOYEE_AUTH_SUCCESS";
export const EMPLOYEE_AUTH_FAIL = "EMPLOYEE_AUTH_FAIL";

export const LIBRARY_AUTH_SUCCESS = "LIBRARY_AUTH_SUCCESS";
export const LIBRARY_AUTH_FAIL = "LIBRARY_AUTH_FAIL";
export const SCHOOL_FOUND = "SCHOOL_FOUND";
export const TEMPLATE1 = "TEMPLATE1";
export const TEMPLATE2 = "TEMPLATE2";
export const SET_LOGIN_TYPE = "SET_LOGIN_TYPE";
export const DECODE_JWT_SUCCESS = "DECODE_JWT_SUCCESS";
export const DECODE_JWT_FAIL = "DECODE_JWT_FAIL";
