export const template1Data = {
  // blue
  backgroundColor: "#3b5950",
  backgroundImage: "linear-gradient(#4e69a2, #3b5998 50%)"
};

export const template2Data = {
  // black
  backgroundColor: "#343a40",
  backgroundImage: "none"
};
